import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

export default function Carousel(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };
  return (
    <StaticQuery
      query={graphql`
        query CarouselQuery {
          sanityCarousel(name: {eq: "Active"}) {
            id
            name
            image1 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image2 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image3 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image4 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image5 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image6 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image7 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image8 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image9 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            image10 {
              asset {
                fluid(maxWidth: 1400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Slider data={data} {...settings} {...props}>
            <div>
              <Img fluid={data.sanityCarousel.image1.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image2.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image3.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image4.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image5.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image6.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image7.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image8.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image9.asset.fluid} />
            </div>
            <div>
              <Img fluid={data.sanityCarousel.image10.asset.fluid} />
            </div>
          </Slider>
        </>
      )}
    />
  );
}
